/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import isFunction from 'lodash/isFunction'

import { ReadonlyRecord } from '@common/lib-types'

import { useLogger } from './use-logger'
import { useMountedRef } from './use-mounted-ref'

export type SetDataParams<T> = T | null | ((_data: T | null) => T | null)
type FetchData<T> = {
  data: T | null
  loading: boolean
  isError: boolean
  setData: (data: SetDataParams<T>) => void
  retry: () => Promise<boolean>
}

type Config = { handleInitialDataLoad: boolean; initialLoading?: boolean }

type FetchDataState<T> = {
  data: T | null
  loading: boolean
  isError: boolean
}

type NotFunctionValue =
  | boolean
  | string
  | number
  | null
  | undefined
  | ReadonlyRecord<string | number | symbol, unknown>
  | ReadonlyArray<unknown>

const InitialConfig: Config = {
  handleInitialDataLoad: true,
  initialLoading: true,
}

// TODO: research migrating to react query. Might be an overkill
export const useFetchData = <T>(
  getData: () => Promise<T>,
  { handleInitialDataLoad, initialLoading }: Config = InitialConfig,
): FetchData<T> => {
  const isMountedRef = useMountedRef()
  const tryNumberRef = useRef<number>(0)
  const logger = useLogger()

  const [state, setState] = useState<FetchDataState<T>>({
    data: null,
    loading: initialLoading ?? true,
    isError: false,
  })
  const mergeState = (update: Partial<FetchDataState<T>>): void => {
    setState((currState) => ({ ...currState, ...update }))
  }

  const getDataRef = useRef(getData)
  getDataRef.current = getData

  const fetchAndSetData = useCallback(async (): Promise<boolean> => {
    tryNumberRef.current++
    const tryNumber = tryNumberRef.current
    // make sure the component is still mounted
    // & there is no other ongoing requests
    const getShouldSet = () =>
      isMountedRef.current && tryNumber === tryNumberRef.current

    let dataWasSet = false

    try {
      mergeState({ loading: true })
      const newData = await getDataRef.current()
      if (getShouldSet()) {
        mergeState({
          data: newData,
          loading: false,
          isError: false,
        })
        dataWasSet = true
      }
    } catch (error) {
      logger.error(error)

      if (getShouldSet()) {
        mergeState({
          isError: true,
          loading: false,
        })
        dataWasSet = true
      }
    }

    return dataWasSet
  }, [])

  // initial data load
  useEffect(() => {
    if (handleInitialDataLoad) {
      void fetchAndSetData()
    }
  }, [])

  const setData = useCallback((dataOrSetter: SetDataParams<T>) => {
    // ignore outgoing request (avoid race conditions)
    tryNumberRef.current++
    setState(({ data, ...other }) => {
      const newData = isFunction(dataOrSetter)
        ? dataOrSetter(data)
        : dataOrSetter
      return {
        data: newData,
        ...other,
      }
    })
  }, [])

  return {
    data: state.data,
    loading: state.loading,
    isError: state.isError,
    setData,
    retry: fetchAndSetData,
  }
}

export const useFetchDataWithDependencies = <T extends NotFunctionValue>(
  getData: () => Promise<T>,
  dependencies: React.DependencyList,
): FetchData<T> => {
  const data = useFetchData(getData, { handleInitialDataLoad: false })

  useEffect(() => {
    void data.retry()
  }, dependencies)

  return data
}
