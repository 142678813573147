import { useEffect, useState } from 'react'

import {
  getAddonProductDescriptions,
  getProductAddons,
  OfferSelectData,
  UIAddonProduct,
  UIOffer,
} from '@src/api'
import {
  trackAddonProductsDisplayed,
  trackAddonProductsEmpty,
} from '@src/tracking'

const findOfferFromId = (id: string, offersData: OfferSelectData): UIOffer => {
  // Flattened List of all offers
  const flattenOffers = [
    offersData.lowestOption,
    ...offersData.flexibleOptions.options,
  ]

  const offer = flattenOffers.find((offer) => offer.id === id)
  return offer
}

type UseAddonProductsParams = {
  offerSelectedId: string
  offerSelectionData: OfferSelectData
  selectedProductIds: ReadonlyArray<string>
}

type UseAddonProductsReturn = {
  addonProducts: ReadonlyArray<UIAddonProduct>
  totalMonthlyCost: string
}

export const useAddonProducts = ({
  offerSelectedId,
  offerSelectionData,
  selectedProductIds,
}: UseAddonProductsParams): UseAddonProductsReturn => {
  const [addonProducts, setAddonProducts] = useState<{
    offerUuid: string
    productData: ReadonlyArray<UIAddonProduct>
  }>({
    offerUuid: null,
    productData: [],
  })

  // Responsible for summing the selected offer and addon products. Expects numbers
  // for the inputs, but returns a formatted string with 2 decimal places.
  const totalMonthlyCost = ((): string => {
    if (!offerSelectedId) {
      return '0'
    }

    // Would like to update this page to avoid react hook forms and be able to hold entire
    // selected offer in state. That would avoid the need for this call, but progress > perfection.
    const offer = findOfferFromId(offerSelectedId, offerSelectionData)
    const selectedProducts = addonProducts.productData.filter((product) =>
      selectedProductIds.includes(product.id),
    )
    const offerTotalCost = offer.monthlyPayment

    const totalAddonsCost = selectedProducts.reduce((acc, product) => {
      return (acc += product.monthlyCost)
    }, 0)

    const totalCost = offerTotalCost + totalAddonsCost
    return totalCost.toLocaleString('en', { minimumFractionDigits: 2 })
  })()

  useEffect(() => {
    if (!offerSelectedId) {
      return
    }

    setAddonProducts((products) =>
      products.offerUuid === offerSelectedId
        ? products
        : {
            offerUuid: products.offerUuid,
            productData: [],
          },
    )

    let cancelled = false
    void (async () => {
      const { addonProducts, offerUuid } = await getProductAddons(
        offerSelectedId,
      )

      if (offerSelectedId === offerUuid && !cancelled) {
        const mappedProducts = addonProducts?.map((addon) => ({
          ...addon,
          ...getAddonProductDescriptions({ type: addon.type }),
        }))

        if (mappedProducts.length === 0) {
          void trackAddonProductsEmpty({ offerUUID: offerSelectedId })
          return
        }

        void trackAddonProductsDisplayed({
          offerUUID: offerSelectedId,
          addonProducts: mappedProducts.map((addon) => ({
            id: addon.id,
            name: addon.name,
            monthlyCost: addon.monthlyCost,
            term: addon.term,
            type: addon.type,
            description: addon.description,
          })),
        })

        setAddonProducts({
          offerUuid: offerSelectedId,
          productData: mappedProducts,
        })
      }
    })()
    return () => {
      cancelled = true
    }
  }, [offerSelectedId])

  return { addonProducts: addonProducts.productData, totalMonthlyCost }
}
