import React, { FC } from 'react'
import { styled } from '@mui/material'

import { TextFieldProps, TextMaskFieldProps } from '@common/types'

import {
  CheckCircleSolidIcon,
  Icon,
  Text,
  TextField,
  TextMaskField,
  WarningSolidIcon,
} from '../../components'

/**
 * @todo Not implemented
 */
const makeImage = null

const NotificationContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.extension.colors.surface.babyGray,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
}))

const MakeImg = styled('div')(({ theme }) => ({
  width: '72px',
  minWidth: '72px',
  height: '72px',
  marginLeft: theme.spacing(2),
}))

const StyledIcon = styled(Icon)({
  width: 24,
  height: 24,
})

const FoundCar: FC<FoundCarProps> = ({ year, make, model, trim }) => (
  <NotificationContainer>
    <div>
      <Text
        use={['h4', { xs: 'subheadings.16b', sm: 'subheadings.18b' }]}
        color="text.softBlack"
        mb={0.5}
      >
        {"We've found your car!"}
      </Text>
      <Text
        use={['p', { xs: 'paragraphs.14', sm: 'paragraphs.16' }]}
        margin={0}
        color="text.softBlack"
      >
        {`${year} ${make} ${model} ${trim}`}
      </Text>
    </div>
    {makeImage && <MakeImg />}
  </NotificationContainer>
)

type FoundCarProps = {
  year: number
  make: string
  model: string
  trim: string
}
type VechicleLookupFieldProps = TextFieldProps &
  Pick<TextMaskFieldProps, 'mask'> & {
    warning?: boolean
    foundCar?: FoundCarProps
    dataTestId?: string
  }

export const VehicleLookupField = React.forwardRef<
  HTMLInputElement,
  VechicleLookupFieldProps
>(({ foundCar, warning, mask, dataTestId, ...props }, ref) => {
  const endIcon = warning ? (
    <StyledIcon use={WarningSolidIcon} color="semantic.warning" />
  ) : foundCar ? (
    <StyledIcon use={CheckCircleSolidIcon} color="base.blueSky" />
  ) : null

  return (
    <div>
      {mask ? (
        <TextMaskField
          {...props}
          mask={mask}
          endIcon={endIcon}
          ref={ref}
          data-testid={dataTestId}
        />
      ) : (
        <TextField
          {...props}
          endIcon={endIcon}
          ref={ref}
          data-testid={dataTestId}
        />
      )}
      {foundCar && <FoundCar {...foundCar} />}
    </div>
  )
})
