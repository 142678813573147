import { FC, useCallback, useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useRouter } from 'next/router'

import {
  Button as CtaButton,
  Notification,
} from '@common/react-lib-consumer-pres'
import { PageRoute } from '@common/types'
import { MaterialProps } from '@lib/mui'

import { vehicleAdapters } from '../../../api'
import {
  useGetNextUrlByLoanState,
  useLogger,
  useResolver,
} from '../../../hooks'
import { trackVehicleOptionsPageSubmited } from '../../../tracking'
import { VehicleOptionsForm, vehicleOptionsSchema } from '../../schemas'
import { Element, genericErrorMessage, useModule } from '../../utils'
import { CheckboxGroupField } from '../controlled-fields'

const useVehcileOptionsForm = (): UseFormReturn<VehicleOptionsForm> => {
  const form = useForm<VehicleOptionsForm>({
    mode: 'onTouched',
    defaultValues: {
      options: [],
    },
    resolver: useResolver(vehicleOptionsSchema),
  })

  return form
}

export const useVehicleOptionsFormModule = (
  options: ReadonlyArray<vehicleAdapters.TVehicleOptions>,
): {
  SubmitError: FC<MaterialProps>
  Submit: FC
  Options: FC
} => {
  const [submitError, setSubmitError] = useState('')
  const hideWarning = useCallback(() => setSubmitError(''), [])

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useVehcileOptionsForm()

  const router = useRouter()
  const getNextUrlByLoanState = useGetNextUrlByLoanState()
  const logger = useLogger()
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const prefetch = async () => {
      await router.prefetch(PageRoute.OfferSelections)
    }
    prefetch().catch(console.error)
  }, [])
  const submitForm = async (values): Promise<void> => {
    try {
      await vehicleAdapters.submitVehicleOptions(values)
      const resumeUrl = await getNextUrlByLoanState()
      void trackVehicleOptionsPageSubmited()
      void router.push(resumeUrl)
    } catch (error) {
      setSubmitError(genericErrorMessage)
      logger.error(error)
    }
  }

  const mod = useModule({
    SubmitError:
      submitError &&
      Element(Notification, {
        type: 'error',
        title: 'Error',
        body: submitError,
        onClose: hideWarning,
      }),
    Submit: Element(CtaButton, {
      onClick: handleSubmit(submitForm),
      loading: isSubmitting || isSubmitSuccessful,
      children: 'Continue',
    }),
    Options: Element(CheckboxGroupField, {
      control,
      items: options.map((item: vehicleAdapters.TVehicleOptions) => ({
        label: item.desc,
        value: item.code,
      })),
      name: 'options',
    }),
  })

  return mod
}
