import {
  EmploymentInfo,
  EmploymentStatus,
  EmploymentStatusKeys,
} from '@common/types'
import { pickNonNil } from '@src/utils'
import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl, refiApiUrl } from '../config'

export type Employment = {
  employmentStatus?: EmploymentStatusKeys
  monthlyGrossIncome?: number
  departureDate?: string
}

export const submitEmployment = async (
  payload: EmploymentInfo,
): Promise<{ resumeUrl: string }> => {
  const body = {
    ...pickNonNil(payload),
  }
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/customer/${getRequestId()}/employment${getLeadSourceQueryParams()}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(body),
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export const getCurrentEmployment = async (
  primaryCustomerUuid: string,
): Promise<Employment | undefined> => {
  const response = await fetch(
    `${refiApiUrl}/services/v2/customer/${primaryCustomerUuid}/employments`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData?.message)
  }
  const data: Array<Employment> = await response.json()

  if (!Array.isArray(data)) {
    return undefined
  }

  const currentEmployment = data.find(
    (employment) => employment?.departureDate === undefined,
  )
  return currentEmployment
}

const employmentStatusMap: Record<EmploymentStatusKeys, String> = {
  [EmploymentStatus.Employed]: 'employed',
  [EmploymentStatus.Retired]: 'retired',
  [EmploymentStatus.SelfEmployed]: 'self-employed',
  [EmploymentStatus.Student]: 'student',
  [EmploymentStatus.Unemployed]: 'Unemployed',
}

export const refiUpdateCurrentEmployment = async (
  uuid: string,
  employment: Employment,
): Promise<void> => {
  const response = await fetch(`${refiApiUrl}/services/v1/employment_detail`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body: JSON.stringify({
      ...employment,
      customerUuid: uuid,
      employmentStatus: employmentStatusMap[employment.employmentStatus],
    }),
  })
  if (!response.ok) {
    throw new Error('Unable to upsert current employment for customer: ${uuid}')
  }
}
