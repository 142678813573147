// eslint-disable-next-line import/no-internal-modules
import { useEffect } from 'react'
import { Router } from 'next/dist/client/router'

import { isBrowser } from '@common/universal-toolkit'

import { trackPage } from '../tracking'

const handleRouteComplete = (url: string): void => {
  console.log(`routeChangeComplete: ${url}`)
  trackPage()
}

export const useTrackPageChange = (router: Router): void => {
  useEffect(() => {
    if (isBrowser) {
      console.log('Attaching router event handler for tracking')
      router.events.on('routeChangeComplete', handleRouteComplete)
      handleRouteComplete(router.route)
    }
    return () => {
      router.events.off('routeChangeComplete', handleRouteComplete)
    }
  }, [router])
}
