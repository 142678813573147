import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { ToolbarButton } from '@common/react-lib-base'
import { IncomeVerificationModule, PageRoute } from '@common/types'

import { submitIncomeVerificationRequirements } from '../../api'
import { useGetNextUrlByLoanState, useLogger } from '../../hooks'
import { trackIncomeVerificationSubmitted } from '../../tracking'
import { Element, genericErrorMessage, useModule } from '../utils'

export const useIncomeVerificationForm = (): {
  submitError: string
  module: IncomeVerificationModule
} => {
  const router = useRouter()
  const [loading, setLoading] = useState({})
  const [submitError, setSubmitError] = useState('')

  const logger = useLogger()
  const getNextUrlByLoanState = useGetNextUrlByLoanState()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const prefetch = async () => {
      await router.prefetch(PageRoute.CreateAccount)
    }
    prefetch().catch(console.error)
  }, [])

  const onSubmit = async (accept: boolean): Promise<void> => {
    try {
      setSubmitError('')
      setLoading({ [accept ? 'yes' : 'no']: true })
      void trackIncomeVerificationSubmitted({ accept: accept ? 'yes' : 'no' })
      await submitIncomeVerificationRequirements({ accept })
      const resumeUrl = await getNextUrlByLoanState()
      void router.push(resumeUrl)
    } catch (error) {
      setSubmitError(genericErrorMessage)
      logger.error(error)
    } finally {
      setLoading({ [accept ? 'yes' : 'no']: false })
    }
  }

  return {
    submitError,
    module: useModule({
      Yes: Element(ToolbarButton, {
        loading: loading['yes'],
        disabled: Object.keys(loading).length > 0 && loading['no'],
        onClick: () => onSubmit(true),
        children: 'Yes, I can provide this',
      }),
      No: Element(ToolbarButton, {
        loading: loading['no'],
        disabled: Object.keys(loading).length > 0 && loading['yes'],
        onClick: () => onSubmit(false),
        children: 'No, I do not have those',
      }),
    }),
  }
}
