import { object, string } from 'yup'

import { VehicleFindBy } from '@common/types'

import { getMaxTest, getMinTest, validVinTest } from './tests'
import { updateYupErrorMessages } from './yup'

updateYupErrorMessages()

type SharedVehicleFields = {
  estimatedMileage: string
  estimatedMonthlyPayment: string
  loanValue: string
}

export const SharedVehicle = object({
  estimatedMileage: string().required(),
  loanValue: string()
    .required()
    .test(
      getMinTest(
        5000,
        'Unfortunately, your vehicle loan balance appears to be too low to qualify for refinancing at this time.',
      ),
    )
    .test(
      getMaxTest(
        1000000,
        'Unfortunately, your vehicle loan balance appears to be too high to qualify for refinancing at this time.',
      ),
    ),
  estimatedMonthlyPayment: string().nullable(true),
})

export type VehicleFormValues = DynamicVehicleFields & SharedVehicleFields

export type DynamicVehicleFields =
  | DynamicVehicleFieldsYMMT
  | DynamicVehicleFieldsVin
  | DynamicVehicleFieldsLP

export type DynamicVehicleFieldsYMMT = {
  findBy: typeof VehicleFindBy.MAKEMODEL
  dynamic: {
    year: string
    make: string
    model: string
    trim: string
  }
}

export type DynamicVehicleFieldsVin = {
  findBy: typeof VehicleFindBy.VIN
  dynamic: {
    vin: string
  }
}

export type DynamicVehicleFieldsLP = {
  findBy: typeof VehicleFindBy.LICENSEPLATE
  dynamic: {
    licensePlateNumber: string
    state: string
  }
}

export const VehicleInfo = SharedVehicle.shape({
  findBy: string()
    .required()
    .oneOf(Object.values(VehicleFindBy), 'This field is required'),

  dynamic: object().when('findBy', (findBy) =>
    findBy === VehicleFindBy.MAKEMODEL
      ? object({
          make: string().required(),
          model: string().required(),
          trim: string().required(),
          year: string().required(),
        })
      : findBy === VehicleFindBy.LICENSEPLATE
      ? object({
          licensePlateNumber: string().required(),
          state: string().required(),
        })
      : findBy === VehicleFindBy.VIN
      ? object({
          vin: string()
            .required()
            .test(
              validVinTest(
                'VIN number is invalid. Please check the VIN number again.',
              ),
            ),
        })
      : object(),
  ),
})
