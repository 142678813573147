import { AdditionalInformationNeededForm } from 'src/pages/the-zebra/additional-information-needed'

import { VehicleFindByKeys } from '@common/types'
import { parseInteger } from '@src/utils'
import { getAuthToken } from '@src/utils/storage'

import {
  DynamicVehicleFieldsLP,
  DynamicVehicleFieldsVin,
  DynamicVehicleFieldsYMMT,
} from '..'
import { consumerXpAggregatorUrl } from '../config'

import { refiUpdateCustomer } from './customer'
import { refiUpdateCurrentEmployment } from './employment'
import { DocumentType, insertLegalAcceptance } from './legal-acceptance'

type DynamicVehicleFields =
  | DynamicVehicleFieldsYMMT
  | DynamicVehicleFieldsVin
  | DynamicVehicleFieldsLP

export type MissingInfoFormParams = {
  estimatedAnnualIncome: string
  citizenship: string
  residenceStatus?: string
  residenceCost?: string
  needsVehicleInfo: boolean
  needsExistingLoanAmount: boolean
  needsVehicleMonthlyPayment: boolean
  needsResidenceStatus: boolean
  needsResidenceCost: boolean
  needsVehicleMileage: boolean
  employmentStatus: string
  livedInAddress: {
    years: number
    months: number
  }
  findBy: VehicleFindByKeys
  mileage?: string
  existingLoanAmount?: string
  vehicleMonthlyPayment?: string
} & DynamicVehicleFields

type MissingInfoFormSubmission = {
  estimatedAnnualIncome: number
  citizenship: string
  residenceStatus?: string
  residenceCost?: number
  monthsInResidence: string
  mileage?: number
  existingLoanAmount?: number
  vehicleMonthlyPayment?: number
}

/**
 * @deprecated moving towards using prequalSubmitMissingInfo for upserts to refi
 */
export const SubmitMissingInfo = async (
  missingInfoParams: MissingInfoFormParams & { nadaUuid?: string },
  requestId: string,
  leadSource: string,
): Promise<void> => {
  const missingInfo: MissingInfoFormSubmission = {
    ...missingInfoParams,
    ...missingInfoParams.dynamic,
    monthsInResidence: `${
      missingInfoParams.livedInAddress.years * 12 +
      missingInfoParams.livedInAddress.months
    }`,
    estimatedAnnualIncome: parseInteger(
      missingInfoParams.estimatedAnnualIncome,
    ),
    residenceStatus: missingInfoParams.residenceStatus,
    residenceCost:
      missingInfoParams.residenceCost &&
      parseInteger(missingInfoParams.residenceCost),
    mileage:
      missingInfoParams.mileage && parseInteger(missingInfoParams.mileage),
    existingLoanAmount:
      missingInfoParams.existingLoanAmount &&
      parseInteger(missingInfoParams.existingLoanAmount),
    vehicleMonthlyPayment:
      missingInfoParams.vehicleMonthlyPayment &&
      parseInteger(missingInfoParams.vehicleMonthlyPayment),
  }
  const body = JSON.stringify({ ...missingInfo })
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/experian/${requestId}/missing-info?leadSource=${leadSource}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body,
    },
  )
  if (!response.ok) {
    throw new Error('failed to submit additional info')
  }
}

export const prequalSubmitMissingInfo = async (
  prequalAdditionalInfo: AdditionalInformationNeededForm,
): Promise<void> => {
  if (!prequalAdditionalInfo.applicant) {
    throw new Error('applicant data is missing, cannot submit missing info')
  }

  void insertLegalAcceptance({
    customerUuid: prequalAdditionalInfo.applicant.primaryCustomerUuid,
    document: DocumentType.CommunicationConsent,
  })

  void refiUpdateCustomer(prequalAdditionalInfo.applicant.primaryCustomerUuid, {
    citizenship: prequalAdditionalInfo.applicant.citizenship,
  })

  void refiUpdateCurrentEmployment(
    prequalAdditionalInfo.applicant.primaryCustomerUuid,
    {
      employmentStatus: prequalAdditionalInfo.applicant.employmentStatus,
      monthlyGrossIncome:
        parseInteger(prequalAdditionalInfo.applicant.estimatedAnnualIncome) /
        12,
    },
  )
}
