/* eslint-disable @typescript-eslint/naming-convention */
export type Offer = {
  uuid: string
  expiration: string
  apr: number
  annualPercentageRate: number
  nominalRate: number
  termInMonths: number
  monthlyPayment: number
  totalPaymentSavings: number
  totalAmountApproved: string
  lenderName: string
  lenderId: number
  fees: ReadonlyArray<{ name: string; amount: number }>
  isTopOffer: boolean
  topOfferReason?: string
  addonProducts?: ReadonlyArray<AddonProduct>
}

export type AddonProduct = {
  id: string
  monthlyCost: number
  term: number
  type: AddonProductTypes
}

export enum AddonProductTypes {
  Gap = 'Gap',
  MBP = 'MBP',
  CosmeticPackage = 'CosmeticPackage',
  KeyReplacement = 'KeyReplacement',
  AllIn = 'AllIn',
}

export type SelectedOffer = {
  statusCode?: number
  amount_financed: string
  term: number
  rate: string
  nominal_annual_percentage_rate: string
  combined_agreement_apr: string
  vehicle_year: number
  vehicle_make: string
  vehicle_trim: string
  vehicle_model: string
  current_monthly_payment: number
  monthly_payment: string
  status_code: number
  remaining_loan_term: number
  lender_id?: number
  lender_name?: string
  selected_products: ReadonlyArray<AddonProduct>
}
