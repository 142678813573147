import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import pickBy from 'lodash/pickBy'
import startCase from 'lodash/startCase'

import { ReadonlyRecord } from '@common/lib-types'

export const pickNonNil = <T extends ReadonlyRecord<string, unknown>>(
  value: T,
): T => pickBy(value, (v) => !isNil(v)) as T

export const DateUtils = {
  UsToIso: (date: string): string => {
    const [month, day, year] = date.split('/')
    return [year, month, day].join('-')
  },
  IsoToUs: (date: string): string => {
    const [year, month, day] = date.split('-')
    return [month, day, year].join('/')
  },
}

/**
 * strips $ and , from a string and converts it into a number, if possible,
 * using Number.parseInt
 */
export const parseInteger = (number: string | number): number =>
  !isNil(number) && !isNumber(number)
    ? Number.parseInt(number.replace('$', '').replace(',', ''))
    : number

type FetchVoid = (...args: ReadonlyArray<unknown>) => Promise<void>
export const makeSafeVoidCall = <T extends FetchVoid>(
  request: T,
  timeout = 500,
): T => {
  return (async (...args) => {
    try {
      await Promise.race([
        request(...args),
        new Promise((resolve) => setTimeout(resolve, timeout)),
      ])
    } catch (error) {
      console.error(error)
    }
  }) as T
}

export const sanitizePhoneNumber = (phone: string): string =>
  phone.replaceAll(/\D/g, '')

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function assert(
  condition: unknown,
  errorMsg = 'Assertion failed',
): asserts condition {
  if (!condition) {
    throw new Error(errorMsg)
  }
}

export const wait = <T = undefined>(time: number, value?: T): Promise<T> =>
  new Promise((res) => setTimeout(() => res(value), time))

export const mapEnumToDisplay = <T extends string>(value: T): string =>
  startCase(value?.toLowerCase?.())

export const normalizeString = <T extends string>(value: T): string =>
  startCase(value?.toLowerCase?.())

export const generateYears = (length = 120): ReadonlyArray<string> =>
  [...Array.from({ length }).keys()].map((i) => `${i}`)

export const generateMonths = (length = 12): ReadonlyArray<string> =>
  [...Array.from({ length }).keys()].map((i) => `${i}`)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ifWindowExist = <T extends (...args: any) => any>(
  callback: T,
): ReturnType<T> | undefined => {
  if (typeof window !== 'undefined') {
    return callback()
  }
}

export const safeWindow = ifWindowExist(() => window)
