import { ReactNode } from 'react'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import z from 'zod'

/**
 * Immutable Record type
 */
export type ReadonlyRecord<
  TKey extends string | number | symbol,
  TVal,
  // eslint-disable-next-line @typescript-eslint/ban-types
> = Readonly<Record<TKey, TVal>>

export type Markdown = string

/**
 * Utility type that asserts that second type param extends first
 *
 * E.g., `type MySubUnion = Extends<MyUnion, 'valid-subset'>`
 */
export type Extends<T, U extends T> = U

/**
 * Utility type that makes every member optional, and recursively
 * makes the members of each member optional as well.
 *
 * From https://stackoverflow.com/a/49936686
 */
export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>
}

export type IsoCountryCode = 'US'
export type IsoCurrencyCode = 'USD'
export type PhoneNumberFormat = string
export type Url = string

/** Safe option for Omit that adds autocomplete for keys */
export type OmitSafe<T extends {}, TKeys extends keyof T> = Omit<T, TKeys>
/** Lodash omit with added autocomplete for keys */
export const omitSafe: <T extends {}, TKeys extends keyof T>(
  obj: T,
  keys: ReadonlyArray<TKeys>,
) => Omit<T, TKeys> = omit
export const pickSafe: <T extends {}, TKeys extends keyof T>(
  obj: T,
  keys: ReadonlyArray<TKeys>,
) => Pick<T, TKeys> = pick

/** Utility type that makes everything partial exepct given keys */
export type PartialBut<T extends {}, TKeys extends keyof T> = Partial<
  Omit<T, TKeys>
> &
  Pick<T, TKeys>

/** Useful for dealing with form values*/
export type DeepMapNumbersToStrings<T extends ReadonlyRecord<string, unknown>> =
  {
    [K in keyof T]: T[K] extends number
      ? string | number
      : T[K] extends ReadonlyRecord<string, unknown>
      ? DeepMapNumbersToStrings<T[K]>
      : T[K]
  }

export type Falsy = null | undefined | '' | 0 | false
export type Truthy<T> = Exclude<T, Falsy>
export const isTruthy = <T>(value: T): value is Truthy<T> => !!value

/**
 * Using this and not React.ComponentType because Typescript can propagate generics only for functions
 */
export type SimpleFunctionComponent<P> = (
  props: P,
) => ReactNode | JSX.Element | null

/**
 * String to Number coercion that preserves `undefined`
 *
 * @param val Value of unknown type to coerce to Number
 * @returns Number if valid String or Number, undefined if undefined,
 *          NaN if invalid
 */
export const coerceStringToNumber = (val: unknown): Number | void => {
  switch (typeof val) {
    case 'number': {
      return val
    }
    // Cast string to number
    case 'string': {
      return Number(val)
    }
    // pass through undefined so that zod can use `default`
    case 'undefined': {
      return void 0
    }
    // If junk, just cast it to NaN so that an appropriate error is thrown
    default: {
      return Number.NaN
    }
  }
}

/**
 * Where this code is running
 *
 * `unknown`: default value, should never be used (if you see this in logs,
 *          investigate why it's not being set correctly)
 *
 * `local-dev`: running locally, e.g. `yarn dev`
 *
 * `dev`: running in dev, e.g. `https://new.apply.caribou-dev.com`
 *
 * `ci`: running in CI, e.g. `yarn test`
 *
 * `review-app`:  running in a review app (can be deprecated?),
 *              e.g. `https://review-app-1234.herokuapp.com`
 *
 * `preview`: running in a preview environment,
 *          e.g. `https://refi-consumer-xp-flow-avnod7014-motorefi.vercel.app/`
 *
 * `staging`: running in a staging environment (can be deprecated?)
 *
 * `prod`: running in production, e.g. `http://new.apply.caribou.com/`
 */
export const Environment = z
  .enum([
    'unknown',
    'local-dev',
    'dev',
    'ci',
    'review-app',
    'preview',
    'staging',
    'prod',
  ])
  .default('unknown')
