import camelcaseKeys from 'camelcase-keys'
import fetchRetry from 'fetch-retry'

import { OmitSafe } from '@common/lib-types'
import { Offer, SelectedOffer } from '@common/types'
import { AddonProduct, AddonProductTypes } from '@common/types/api/offers'
import { Logger } from '@src/hooks/use-logger'
import {
  getAuthToken,
  getLeadSource,
  getLeadSourceAndLeadCampaignQueryParams,
  getRequestId,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl, refiApiUrl } from '../config'

export type ExistingLoan = {
  vehicle_year: number
  vehicle_make: string
  vehicle_model: string
  vehicle_trim: string
  bureau: string
  current_loan_amount: number
  remaining_loan_term: number
  current_apr: number
  current_monthly_payment: number
}

export type UIOffer = {
  id: string
  monthlyPayment: number
  rate: number
  apr?: number
  annualPercentageRate?: number
  savingPotential: number
  term: number
  totalPaymentSavings: number
  lenderName: string
  lenderId: number
  amountFinanced: number
  isTopOffer: boolean
  topOfferReason?: string
}

export type UIAddonProduct = AddonProduct & {
  name: string
  description: string
}

export type UIExistingLoan = {
  carModel: string
  savingPotential: number
  estimatedRate: number
  monthlyPayment: number
  remainingTerm: number
  outstandingAmount: number
  bureau: string
}

export type OfferSelectData = {
  currentLoanInfo: UIExistingLoan
  lowestOption: UIOffer
  flexibleOptions: {
    amountFinanced: number
    options: ReadonlyArray<UIOffer>
  }
}

export type GetProductAttachmentsResponse = {
  offerUuid: string
  addonProducts: ReadonlyArray<AddonProduct>
}

type GetApiAddonProductResponse = {
  offerUuid: string
  addonProducts: ReadonlyArray<ApiAddonProduct>
}

type ApiAddonProduct = OmitSafe<AddonProduct, 'monthlyCost'> & {
  monthlyCost: string
}

type ApiOffer = OmitSafe<Offer, 'monthlyPayment'> & {
  monthlyPayment: string
}

const getOffers = async (logger?: Logger): Promise<ReadonlyArray<Offer>> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${getRequestId()}/offers${getLeadSourceAndLeadCampaignQueryParams()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )

  const offers = (await response.json()) as ReadonlyArray<ApiOffer>

  if (logger) {
    logger.info('offers loaded', offers)
  }

  return offers.map((offer) => ({
    ...offer,
    monthlyPayment: Number.parseFloat(offer.monthlyPayment),
  }))
}

export const getProductAddons = async (
  offerUuid: string,
  logger?: Logger,
): Promise<GetProductAttachmentsResponse> => {
  const fetchWithRetry = fetchRetry(fetch, {
    retries: 5,
    retryDelay: (attempt) => attempt * 1000,
    retryOn: async (_, error) => !!error,
  })
  try {
    const response = await fetchWithRetry(
      `${refiApiUrl}/services/v1/addon_product/offer/${offerUuid}`,
      {
        method: 'GET',
        redirect: 'error',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    )

    const addonProductsResponseRaw =
      (await response.json()) as unknown as GetApiAddonProductResponse

    // Returns the same object with snake_case keys converted to camelCase
    // Refi API uses snake_case, but camelCase is more idiomatic in JS
    const addonProductsResponse = camelcaseKeys(addonProductsResponseRaw, {
      deep: true,
    })

    const addonProducts: ReadonlyArray<AddonProduct> =
      addonProductsResponse.addonProducts.map((addon) => ({
        ...addon,
        monthlyCost: Number.parseFloat(addon.monthlyCost),
      }))

    return {
      offerUuid: addonProductsResponse.offerUuid,
      addonProducts,
    }
  } catch (error) {
    if (logger) {
      logger.error('failed to fetch addon products', { error: error })
    }
    return {
      offerUuid,
      addonProducts: [],
    }
  }
}

export const getOffersWithProductAttachments = async (
  logger?: Logger,
): Promise<ReadonlyArray<Offer>> => {
  const offers = await getOffers(logger)
  const offersWithAddonProducts = await Promise.all(
    offers.map(async (offer) => {
      const offerUuid = offer.uuid
      const { addonProducts } = await getProductAddons(offerUuid, logger)
      return {
        ...offer,
        addonProducts,
      }
    }),
  )

  return offersWithAddonProducts
}

export const getExistingLoan = async (): Promise<ExistingLoan> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${getRequestId()}/existing-loan${getLeadSourceAndLeadCampaignQueryParams()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )

  return response.json()
}

const calculateAmountFinanced = (offer: Offer): number => {
  const totalFees = offer.fees
    .filter((offer) => !offer.name.includes('processing'))
    .reduce((total, offer) => Number(offer.amount) + total, 0)

  return Number(offer.totalAmountApproved) - totalFees
}

type AddonProductsDescription = { name: string; description: string }
const addonProductDescriptionsByTypes: Record<
  AddonProductTypes,
  AddonProductsDescription
> = {
  Gap: {
    name: 'GAP',
    description:
      "Just in case your car is totaled or stolen while there's still a balance left on your loan, GAP keeps your loan from going underwater.",
  },
  MBP: {
    name: 'Mechanical Breakdown Protection',
    description:
      "Offload the price of unexpected repairs - long after your vehicle's factory warranty expires.",
  },
  AllIn: {
    name: 'All in one',
    description:
      'Cover all your bases with our most comprehensive protection package. From tires and wheels to dents and dings, missing keys, and even roadside assistance - All In One has got you covered.',
  },
  CosmeticPackage: {
    name: 'Cosmetic Care Package',
    description:
      'Keep your car looking shiny and new with easy fixes for dents and dings.',
  },
  KeyReplacement: {
    name: 'Key Replacement & Roadside Assistance',
    description:
      'Drive with peace of mind knowing you have 24/7 emergency services, including road-side assistance, delivery of emergency supplies like gasoline or water, and battery boosts. Also covers key replacements for lost, stolen, or damaged keys.',
  },
}

export const getAddonProductDescriptions = ({
  type,
}: {
  type: AddonProductTypes
}): AddonProductsDescription => addonProductDescriptionsByTypes[type]

type GetOfferSelectDataConfig = {
  logger?: Logger
  priorityOffer?: 'topOffer' | 'lowestOption'
}
export const getOfferSelectData = async (
  config?: GetOfferSelectDataConfig,
): Promise<OfferSelectData> => {
  const { logger, priorityOffer = 'topOffer' } = config || {}

  const [offers = [], loan] = await Promise.all([
    getOffers(logger),
    getExistingLoan(),
  ])
  const {
    bureau,
    current_loan_amount,
    remaining_loan_term,
    vehicle_year,
    vehicle_make,
    vehicle_model,
    current_monthly_payment,
    current_apr,
  } = loan

  const getTopOffer = (): Offer => offers.find((x) => x.isTopOffer)
  const getLowerPaymentOffer = (): Offer =>
    offers.reduce(
      (previousOffer, newOffer) => {
        return Number(previousOffer.monthlyPayment) <
          Number(newOffer.monthlyPayment)
          ? previousOffer
          : newOffer
      },
      offers.length > 0 ? offers[0] : null,
    )

  const preferredOffer =
    priorityOffer === 'topOffer' ? getTopOffer() : getLowerPaymentOffer()

  const flexibleOptions = offers.filter(
    (offer) => offer.uuid !== preferredOffer?.uuid,
  )

  const calculateMonthlySavingsPotential = (offer: Offer): number => {
    return Number(current_monthly_payment) - Number(offer.monthlyPayment)
  }

  const mapApiOfferToUIOffer = (offer: Offer): UIOffer => {
    return {
      id: offer.uuid,
      monthlyPayment: offer.monthlyPayment,
      rate: offer.nominalRate,
      totalPaymentSavings: offer.totalPaymentSavings,
      lenderName: offer.lenderName,
      lenderId: offer.lenderId,
      savingPotential: calculateMonthlySavingsPotential(offer),
      term: offer.termInMonths,
      amountFinanced: calculateAmountFinanced(offer),
      apr: offer?.apr,
      annualPercentageRate: offer?.annualPercentageRate,
      isTopOffer: offer.isTopOffer,
      topOfferReason: offer.topOfferReason,
    }
  }

  return {
    currentLoanInfo: {
      carModel: `${vehicle_year} ${vehicle_make} ${vehicle_model}`,
      savingPotential:
        preferredOffer && calculateMonthlySavingsPotential(preferredOffer),
      estimatedRate: current_apr,
      monthlyPayment: current_monthly_payment,
      remainingTerm: remaining_loan_term,
      outstandingAmount: current_loan_amount,
      bureau: bureau.replace('Transunion', 'TransUnion'),
    },
    lowestOption: preferredOffer && mapApiOfferToUIOffer(preferredOffer),
    flexibleOptions: {
      amountFinanced: preferredOffer && calculateAmountFinanced(preferredOffer),
      options: flexibleOptions.map((offer) => mapApiOfferToUIOffer(offer)),
    },
  }
}

export const submitLoan = async (
  offerUuid: string,
  addonProducts?: Array<string>,
): Promise<void> => {
  await fetch(`${refiApiUrl}/services/v1/offer/${offerUuid}/select`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
      'Referrer-Policy': 'no-referrer-when-downgrade',
    },
    body: addonProducts
      ? JSON.stringify({
          addon_products: addonProducts,
        })
      : undefined,
  })
}

export const getSelectedOffer = async (): Promise<SelectedOffer> => {
  const requestId = getRequestId()
  const leadSource = getLeadSource()
  const partnerLeadSourceResponse = await fetch(
    `${refiApiUrl}/services/v3/partner_lead_source/source_name/${leadSource}/request_id/${requestId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const { loanApplicationUuid } = await partnerLeadSourceResponse.json()

  const [selectedOfferResponse, existingLoanResponse] = await Promise.all([
    fetch(
      `${refiApiUrl}/services/v2/loan_application/${loanApplicationUuid}/selected_offer`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    ),
    fetch(
      `${refiApiUrl}/services/v1/credit_report_profile/existing_loan/${loanApplicationUuid}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getAuthToken(),
        },
      },
    ),
  ])
  const [selectedOffer, existingLoan] = await Promise.all([
    selectedOfferResponse.json(),
    existingLoanResponse.json(),
  ])

  return {
    ...selectedOffer.selected_offer_information,
    ...selectedOffer.selected_offer_lender,
    ...existingLoan,
    selected_products: selectedOffer.selected_products.map((product) => ({
      ...product,
      monthlyCost: Number(product.monthly_cost),
    })),
    // override offer monthly_payment with total_monthly_payment bc display is always be total
    monthly_payment: selectedOffer.total_monthly_payment,
  }
}
