import { CSSProperties, FC } from 'react'

import { MaterialProps } from '@lib/mui'

export type OfferSelectionsCmsData = {
  heading: string
  subheading: string
  disclosures: ReadonlyArray<string>
}

export type OfferSelectionCurrentLoanInfo = {
  carModel: string
  savingPotential: number
  outstandingAmount: number
  remainingTerm: number
  estimatedRate: number
  monthlyPayment: number
  bureau: string
}

export type OfferSelectionLoanLowestOption = {
  savingPotential: number
  amountFinanced: number
  term: number
  rate: number
  apr?: number
  annualPercentageRate?: number
  monthlyPayment: number
  totalPaymentSavings: number
  selected?: boolean
  onClick?: (id: number | string) => void
  id: number | string
  currentMonthlyPayment?: number
  style?: CSSProperties
  currentRate?: number
  hasRecommendedTopOffer: boolean
  lenderName: string
}

type FlexibleOption = {
  savingPotential: number
  term: number
  rate: number
  apr?: number
  annualPercentageRate?: number
  totalPaymentSavings: number
  lenderName: string
  lenderId: number
  monthlyPayment: number
  id: number | string
}

export type OfferSelectionLoanFlexibleOption = {
  amountFinanced: number
  options: ReadonlyArray<FlexibleOption>
  selectedTerm: string | number
  onTermChange: (id: number | string) => void
  selected: boolean
  onSelect: () => void
  currentMonthlyPayment: number
  style?: CSSProperties
  annualPercentageRate?: number
  currentRate?: number
  hasRecommendedTopOffer: boolean
}

export type OfferSelectionsFormProps = {
  module: {
    LowestOption: FC
    FlexibleOptions: FC
    ProductAttachments?: FC
  }
  Submit: FC<{ className?: string }>
  SubmitError: FC<MaterialProps>
  onCarouselSwipeTo: (option: EOfferOption) => void
  currentLoanInfo: OfferSelectionCurrentLoanInfo
  disclosures?: ReadonlyArray<string>
  hasRecommendedTopOffer: boolean
  showProductAttachments?: boolean
  totalMonthlyCost?: string
}

export enum EOfferOption {
  lowest = 'lowest',
  flexible = 'flexible',
}

export type OfferSelectionsPageContent = {
  heading: string
  subheading: string
  disclosures: ReadonlyArray<string>
}
