import qs from 'querystring'

import { ifWindowExist } from './utils'

export const LeadSource = {
  CREDIT_KARMA: 'credit-karma',
  MR_CONSUMER_SITE: 'mr-consumer-site',
  CONSUMER_AFFAIRS: 'consumer-affairs',
  WEYLAND: 'weyland',
  EXPERIAN: 'experian',
  THE_ZEBRA: 'the-zebra',
  NERDWALLET: 'nerdwallet',
} as const

export type LeadSourceKeys = (typeof LeadSource)[keyof typeof LeadSource]

export enum LeadCampaign {
  LIGHTBOX = 'lb',
}

const AUTH_TOKEN_KEY = 'AUTH_TOKEN'
const REQUEST_ID_KEY = 'REQUEST_ID'
const LEAD_SOURCE_KEY = 'LEAD_SOURCE'
const LEAD_CAMPAIGN_KEY = 'LEAD_CAMPAIGN'
const EMAIL_KEY = 'EMAIL'

export const setAuthToken = (authToken: string): void => {
  ifWindowExist(() => localStorage.setItem(AUTH_TOKEN_KEY, authToken))
}

export const setRequestId = (requestId: string): void => {
  ifWindowExist(() => localStorage.setItem(REQUEST_ID_KEY, requestId))
}

export const setLeadSource = (leadSource: LeadSourceKeys): void => {
  ifWindowExist(() => localStorage.setItem(LEAD_SOURCE_KEY, leadSource))
}
export const setLeadCampaign = (LeadCampaign: LeadCampaign): void => {
  ifWindowExist(() => localStorage.setItem(LEAD_CAMPAIGN_KEY, LeadCampaign))
}

export const setEmail = (email: string): void => {
  ifWindowExist(() => localStorage.setItem(EMAIL_KEY, email))
}

export const getRequestId = (): string | null =>
  ifWindowExist(() => localStorage.getItem(REQUEST_ID_KEY))

export const getAuthToken = (): string | null =>
  ifWindowExist(() => localStorage.getItem(AUTH_TOKEN_KEY))

export const getLeadSource = (): LeadSourceKeys | undefined =>
  ifWindowExist(
    () => localStorage.getItem(LEAD_SOURCE_KEY) as LeadSourceKeys | undefined,
  )
export const getLeadCampaign = (): LeadCampaign | undefined =>
  ifWindowExist(
    () => localStorage.getItem(LEAD_CAMPAIGN_KEY) as LeadCampaign | undefined,
  )

export const getEmail = (): string | null =>
  ifWindowExist(() => localStorage.getItem(EMAIL_KEY))

export const getLeadSourceQueryParams = (): string => {
  const leadSource = getLeadSource()
  return leadSource ? `?leadSource=${leadSource}` : ''
}

export const getLeadSourceAndLeadCampaignQueryParams = (): string => {
  const leadSource = getLeadSource()
  const leadCampaign = getLeadCampaign()

  return leadSource || leadCampaign
    ? `?${qs.stringify({
        ...(leadSource && { leadSource }),
        ...(leadCampaign && { leadCampaign }),
      })}`
    : ''
}

export const getLeadSourceAndRequestIdQueryParams = (): string => {
  const requestId = getRequestId()
  const queryParams = `?requestId=${requestId}`
  const leadSource = getLeadSource()
  return leadSource ? `${queryParams}&leadSource=${leadSource}` : queryParams
}

export const removeAuthToken = (): void => {
  ifWindowExist(() => localStorage.removeItem(AUTH_TOKEN_KEY))
}

export const removeRequestId = (): void => {
  ifWindowExist(() => localStorage.removeItem(REQUEST_ID_KEY))
}

export const removeLeadSource = (): void => {
  ifWindowExist(() => localStorage.removeItem(LEAD_SOURCE_KEY))
}
