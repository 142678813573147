/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import getConfig from 'next/config'
import z from 'zod'

import { coerceStringToNumber, Environment } from '@common/lib-types'

/**
 * CRITICAL: Ensure that `next.config.js` exposes
 * expected variables in `publicRuntimeConfig`
 */
const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()

export const serverConfig = serverRuntimeConfig as {
  auth0ClientSecret: string
}

export const publicAuth0Config = publicRuntimeConfig as {
  auth0ClientId: string
  auth0Domain: string
  auth0Audience: string
}

export const environment = Environment.parse(publicRuntimeConfig.environment)

export const segmentBrowserKey: string = publicRuntimeConfig.segmentBrowserKey
export const hasuraUrl: string = publicRuntimeConfig.hasuraUrl
export const consumerXpAggregatorUrl: string =
  publicRuntimeConfig.consumerXpAggregatorUrl
export const vehiclesServiceUrl: string = publicRuntimeConfig.vehiclesServiceUrl
export const prefillServiceApiUrlPartner: string =
  publicRuntimeConfig.prefillServiceApiUrlPartner
export const refiApiKey: string = publicRuntimeConfig.refiApiKeyForMrWeb

export const refiApiUrl: string = publicRuntimeConfig.refiApiUrl
export const refiPartnerAggBaseUrl: string =
  publicRuntimeConfig.refiPartnerAggBaseUrl
export const consumerDashboardUrl: string =
  publicRuntimeConfig.consumerDashboardUrl
export const googleMapsApiKey: string = publicRuntimeConfig.googleMapsApiKey

/* DATADOG */
const DatadogRumSessionSamplingRate = z.preprocess(
  coerceStringToNumber,
  z.number().min(0).max(100).default(0),
)
const DatadogRumSessionReplaySamplingRate = z.preprocess(
  coerceStringToNumber,
  z.number().min(0).max(100).default(0),
)
const DatadogRumTrackUserInteractions = z.preprocess(
  (val) => val === 'true' || val === 'True',
  z.boolean(),
)

const AuthGuardDisabled = z.preprocess(
  (val) => val === 'true' || val === 'True',
  z.boolean(),
)

export const datadogRumApplicationId: string =
  publicRuntimeConfig.datadogRumApplicationId
export const datadogRumClientToken: string =
  publicRuntimeConfig.datadogRumClientToken
export const datadogRumSite: string = publicRuntimeConfig.datadogRumSite
export const datadogRumService: string = publicRuntimeConfig.datadogRumService
export const datadogRumVersion: string = publicRuntimeConfig.datadogRumVersion

export const datadogRumSessionSamplingRate =
  DatadogRumSessionSamplingRate.parse(
    publicRuntimeConfig.datadogRumSessionSamplingRate,
  )
export const datadogRumSessionReplaySamplingRate =
  DatadogRumSessionReplaySamplingRate.parse(
    publicRuntimeConfig.datadogRumSessionReplaySamplingRate,
  )
export const datadogRumTrackUserInteractions =
  DatadogRumTrackUserInteractions.parse(
    publicRuntimeConfig.datadogRumTrackUserInteractions,
  )

export const datadogLogsClientToken: string =
  publicRuntimeConfig.datadogLogsClientToken

export const authGuardDisabled: boolean = AuthGuardDisabled.parse(
  publicRuntimeConfig.authGuardDisabled,
)

export const decodedJwtTokenDataKey: string =
  publicRuntimeConfig.decodedJwtTokenDataKey

export const customLenderProcessingFees = {
  becu: 549,
  hughes: 559,
}

export const caribouPhone = '1-877-445-0070'
