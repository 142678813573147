/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useCallback, useState } from 'react'
import { useRouter } from 'next/router'

import {
  Button,
  Notification,
  onScrollFinish,
  RadioGroup,
  useShake,
} from '@common/react-lib-consumer-pres'
import {
  Citizenship,
  citizenshipDisplay,
  CoborrowerInformationFormModule,
  EmploymentStatus,
  EmploymentStatusKeys,
  ERelationship,
  ResidenceStatus,
  SelectFieldProps,
  State,
  stateToDisplay,
} from '@common/types'
import { mapEnumToDisplay } from '@src/utils'

import { useGetNextUrlByLoanState, useLogger } from '../../../hooks'
import { trackCoborrowerFoundPageSubmitted } from '../../../tracking'
import { CoborrowerInformationForm } from '../../schemas'
import {
  DateField,
  Element,
  genericErrorMessage,
  NewTextField as TextField,
  NumberField,
  SelectField,
  useModule,
} from '../../utils'
import {
  CurrencyInput as CurrencyField,
  PhoneInput,
} from '../controlled-fields'

import { useCoborrowerForm } from './form'
import { useBorrowerEmailField } from './use-borrower-email'

type CoborrowerInformationFormPage = {
  module: CoborrowerInformationFormModule
  sameAddress: boolean
}

const getYears = (): ReadonlyArray<string> =>
  [...Array.from({ length: 120 }).keys()].map((i) => `${i}`)

const getMonths = (): ReadonlyArray<string> =>
  [...Array.from({ length: 12 }).keys()].map((i) => `${i}`)

const yesNoMenuItemsSameAddress = [
  {
    label: 'Yes',
    value: true,
    id: 'same-address-yes-btn',
  },
  {
    label: 'No',
    value: false,
    id: 'same-address-no-btn',
  },
]

const residencyStatus: SelectFieldProps['menuItems'] = [
  {
    value: '',
    displayName: 'Select a status',
  },
  {
    value: Citizenship.UsCitizen,
    displayName: citizenshipDisplay[Citizenship.UsCitizen],
  },
  {
    value: Citizenship.PermanentResident,
    displayName: citizenshipDisplay[Citizenship.PermanentResident],
  },
  {
    value: Citizenship.NonPermanentResident,
    displayName: citizenshipDisplay[Citizenship.NonPermanentResident],
  },
  {
    value: Citizenship.NonResidentAlien,
    displayName: citizenshipDisplay[Citizenship.NonResidentAlien],
  },
]
const mapEmploymentToDisplay = (value: EmploymentStatusKeys): string =>
  value === EmploymentStatus.SelfEmployed
    ? 'Self-employed'
    : mapEnumToDisplay(value)

export const useCoborrowerInformationFormModule =
  (): CoborrowerInformationFormPage => {
    const form = useCoborrowerForm()
    const router = useRouter()
    const {
      control,
      watch,
      setValue,
      handleSubmit,
      formState,
      trigger,
      submit: submitForm,
    } = form
    const [submitError, setSubmitError] = useState('')
    const hideWarning = useCallback(() => setSubmitError(''), [])
    const { isSubmitting, isSubmitSuccessful } = formState
    const getNextUrlByLoanState = useGetNextUrlByLoanState()
    const logger = useLogger()
    const shake = useShake()

    const {
      element: EmailField,
      errorComponent: EmailExistError,
      errorRef,
      showCustomError,
    } = useBorrowerEmailField({
      staticProps: { label: 'Email address' },
      control,
      trigger,
    })

    const submit = handleSubmit(
      async (values: CoborrowerInformationForm): Promise<void> => {
        try {
          await submitForm({ ...values })

          void trackCoborrowerFoundPageSubmitted({
            addressSameAsBorrower: values.sameAddress,
          })

          const nextUrl = await getNextUrlByLoanState()
          void router.push(nextUrl)
        } catch (error) {
          logger.error(error)
          setSubmitError(genericErrorMessage)
          throw error
        }
      },
      () => {
        if (showCustomError) {
          errorRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
          onScrollFinish(() => shake(errorRef.current))
        }
      },
    )

    const watchAddress = watch('sameAddress') ?? false
    control.register('sameAddress')

    return {
      module: useModule({
        SameAddress: Element(RadioGroup, {
          value: watchAddress,
          items: yesNoMenuItemsSameAddress,
          name: 'sameAddress',
          variant: 'simple',
          control,
          onChange: (val: boolean) => {
            setValue('sameAddress', val, { shouldValidate: true })
          },
        }),
        Submit: Element(Button, {
          onClick: submit,
          loading: isSubmitting || isSubmitSuccessful,
          children: 'Continue',
        }),
        SubmitError:
          !!submitError &&
          Element(Notification, {
            type: 'error',
            title: 'Error',
            body: submitError,
            onClose: hideWarning,
            scrollIntoView: true,
            shake: true,
          }),
        FirstName: Element(TextField, {
          label: 'First name',
          control,
          name: 'firstName',
        }),
        LastName: Element(TextField, {
          label: 'Last name',
          control,
          name: 'lastName',
        }),
        Cellphone: Element(PhoneInput, {
          label: 'Mobile phone number',
          control,
          name: 'phoneNumber',
        }),
        Email: EmailField,
        EmailExistError,
        ConfirmEmail: Element(TextField, {
          label: 'Confirm email address',
          control,
          disabled: showCustomError,
          name: 'confirmEmailAddress',
          onBlur: (e) => {
            setValue('confirmEmailAddress', e?.target?.value?.trim(), {
              shouldValidate: true,
            })
          },
        }),
        Birthdate: Element(DateField, {
          label: 'Date of birth',
          control,
          name: 'dob',
          placeholder: 'mm/dd/yyyy',
        }),
        Citizenship: Element(SelectField, {
          control,
          name: 'citizenship',
          label: 'U.S. residency status of co-borrower',
          menuItems: residencyStatus,
        }),
        Relationship: Element(SelectField, {
          control,
          label: 'What is your relationship to the co-borrower?',
          name: 'relationship',
          menuItems: [
            {
              displayName: 'Select an option',
              value: '',
            },
            {
              displayName: 'Spouse',
              value: ERelationship.spouse,
            },
            {
              displayName: 'Child',
              value: ERelationship.child,
            },
            {
              displayName: 'Parent',
              value: ERelationship.parent,
            },
            {
              displayName: 'Significant Other',
              value: ERelationship.significantOther,
            },
            {
              displayName: 'Friend',
              value: ERelationship.friend,
            },
          ],
        }),
        Address: Element(TextField, {
          control,
          label: 'Street address',
          name: 'address',
        }),
        Apartment: Element(TextField, {
          control,
          label: 'Apartment or suite # (optional)',
          name: 'apartment',
          placeholder: 'Suite #',
        }),
        City: Element(TextField, {
          control,
          label: 'City',
          name: 'city',
          placeholder: 'City',
        }),
        State: Element(SelectField, {
          control,
          name: 'state',
          label: 'State',
          menuItems: [
            {
              displayName: 'Select a state',
              value: '',
            },
            ...Object.values(State).map((state) => ({
              displayName: stateToDisplay(state),
              value: state,
            })),
          ],
        }),
        Zip: Element(NumberField, {
          control,
          label: 'Zip',
          name: 'zip',
          format: '#####',
          mask: '_',
          placeholder: '5 digit ZIP code',
        }),
        MovedInYear: Element(SelectField, {
          control,
          name: 'livedInAddress.years',
          label: 'Years',
          variant: 'small',
          menuItems: [
            {
              displayName: 'Select',
              value: '',
            },
            ...getYears().map((value) => ({
              value,
              displayName: value,
            })),
          ],
        }),
        MovedInMonth: Element(SelectField, {
          control,
          name: 'livedInAddress.months',
          label: 'Months',
          variant: 'small',
          menuItems: [
            {
              displayName: 'Select',
              value: '',
            },
            ...getMonths().map((value) => ({
              value,
              displayName: value,
            })),
          ],
        }),
        RentOrOwn: Element(SelectField, {
          control,
          label: 'Do you rent or own?',
          name: 'rentOrOwn',
          menuItems: [
            {
              displayName: 'Select an option',
              value: '',
            },
            ...Object.values(ResidenceStatus).map((value) => ({
              value,
              displayName: mapEnumToDisplay(value),
            })),
          ],
        }),
        MonthlyPayment: Element(CurrencyField, {
          control,
          label: 'Monthly Payment',
          name: 'monthlyPayment',
          placeholder: '$',
        }),
        EmploymentStatus: Element(SelectField, {
          control,
          label: 'Select employment status',
          name: 'employmentStatus',
          menuItems: [
            {
              displayName: 'Select status',
              value: '',
            },
            ...Object.values(EmploymentStatus).map((value) => ({
              value,
              displayName: mapEmploymentToDisplay(value),
            })),
          ],
        }),
        YearlyIncome: Element(CurrencyField, {
          control,
          label: 'Yearly income (before taxes)',
          name: 'yearlyIncome',
          placeholder: '$',
        }),
      }),
      sameAddress: watchAddress,
    }
  }
