import React, { FC } from 'react'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'

import { OfferSelectionsFormProps } from '@common/types'

import { ContinueButton, Link, SaleDuotoneIcon, Text } from '../../reskin'

import { CurrentLoanInfo } from './current-loan-info'
import { CardsCarousel } from './loan-carousel'
import { OfferSelectionsHeader } from './offer-selections-components'

const CardContainer = styled(Box)({
  display: 'flex',
})

const Divider = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
}))

const SectionDivider = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(3),
  },
}))

const CallToActionContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.extension.colors.divider.lightGray}`,
  borderRadius: '12px',
  margin: theme.spacing(6, 0, 4, 0),
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(4.5, 0, 3),
    padding: theme.spacing(2),
  },
}))

const CallToActionText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    display: 'block',
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(-0.5),
  },
}))

const TermsText = styled('p')({
  flex: 'none',
  order: 0,
  flexGrow: 0,
  margin: 0,
  fontSize: '12px',
  lineHeight: '24px',
})

const CallUsText = styled(Link)(({ theme }) => ({
  ...theme.extension.typography.subheadings['18b'],
  [theme.breakpoints.down('md')]: {
    ...theme.extension.typography.subheadings['16b'],
  },
}))

const TermsContainer = styled(Box)(({ theme }) => ({
  ...theme.extension.typography.paragraphs[18],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '8px',
  marginTop: theme.spacing(3),
  // TODO: The marginBottom rules here cause this component to overlap with the footer
  // We should clean  this up at some point.
  marginBottom: theme.spacing(-4),
  [theme.breakpoints.down('md')]: {
    ...theme.extension.typography.paragraphs[16],
    margin: 0,
    marginBottom: theme.spacing(-1),
  },
}))

const Section = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(6),
  borderBottom: `1px solid ${theme.extension.colors.divider.lightGray}`,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))

const HeroContainer = styled(Box)({
  position: 'relative',
})

const defaultProps = {
  disclosures: [
    '1. This offer is valid for 30 days. Rates are not guaranteed and are subject to change without notice.',
    '2. Estimated based on Transunion report data.',
    '3. Based on a comparison with your existing loan.',
    '4. Your new amount financed will include a $499 - $559 processing fee for services and retitling your vehicle.',
    '5.  The annual percentage rate (APR) is the total yearly cost of borrowing money. APR is expressed as a percentage and includes the fees charged for the extension of credit.',
  ],
}

export const OfferSelectionsForm: FC<OfferSelectionsFormProps> = (props) => {
  const {
    module: { LowestOption, FlexibleOptions, ProductAttachments },
    currentLoanInfo,
    disclosures = defaultProps.disclosures,
    Submit,
    SubmitError,
    onCarouselSwipeTo,
    totalMonthlyCost,
  } = props

  const theme = useTheme()
  const showCarousel = useMediaQuery(theme.breakpoints.down(750))

  return (
    <>
      <HeroContainer>
        <OfferSelectionsHeader />
        {SubmitError && <SubmitError sx={{ mt: 4, mb: -2 }} />}
        <Text
          use={['h1', { xs: 'headings.32', sm: 'headings.48' }]}
          color="text.white"
          mt={{ xs: 4, sm: 6 }}
        >
          Choose your new loan
        </Text>

        <Text
          use={['h1', { xs: 'subheadings.16m', sm: 'subheadings.18m' }]}
          color="text.white"
          mt={{ xs: 1, sm: 2 }}
          mb={{ xs: 3, sm: 5 }}
          mx={0}
        >
          Estimated amount financed{' '}
          {`$${currentLoanInfo.monthlyPayment.toLocaleString('en', {
            minimumFractionDigits: 2,
          })}/mo`}
        </Text>
      </HeroContainer>
      <Section>
        <CurrentLoanInfo {...currentLoanInfo} />
        {showCarousel ? (
          <CardsCarousel
            LowestOption={LowestOption}
            FlexibleOptions={FlexibleOptions}
            onSwipeTo={onCarouselSwipeTo}
          />
        ) : (
          <CardContainer>
            <LowestOption />
            <Divider />
            <FlexibleOptions />
          </CardContainer>
        )}
      </Section>
      {ProductAttachments && <SectionDivider />}
      {ProductAttachments && (
        <Section>
          <ProductAttachments />
          <Section sx={{ pb: 0, mb: 6 }} />
          <Box justifyContent="space-between" display="flex">
            <Text
              color="text.black"
              use={['span', { xs: 'subheadings.18b', sm: 'subheadings.24b' }]}
            >
              Total
            </Text>

            <Box>
              <Text
                color="base.blueSky"
                use={['span', { xs: 'subheadings.18b', sm: 'subheadings.24b' }]}
              >
                ${totalMonthlyCost}
              </Text>
              <Text
                color="base.blueSky"
                use={['span', { xs: 'subheadings.18m', sm: 'subheadings.24m' }]}
              >
                /mo
              </Text>
            </Box>
          </Box>
        </Section>
      )}

      <Box mt={5}>
        <ContinueButton button={Submit} />
      </Box>

      <CallToActionContainer>
        <CallToActionText>
          <IconContainer>
            <SaleDuotoneIcon />
          </IconContainer>
          <Text
            use={['h1', { xs: 'paragraphs.16', sm: 'paragraphs.18' }]}
            color="text.softBlack"
            margin={0}
          >
            {
              'Additional terms may be available. Please contact a loan officer at '
            }
            <CallUsText href={`tel:${'1-877-445-0070'}`}>
              {'1.877.445.0070'}
            </CallUsText>
          </Text>
        </CallToActionText>
      </CallToActionContainer>

      <Section />

      <TermsContainer>
        <Text use={['p', 'subheadings.14sb']} color="text.softBlack" margin={0}>
          DISCLOSURES
        </Text>
        {disclosures.map((disclosure, i) => (
          <TermsText key={i}>{disclosure}</TermsText>
        ))}
      </TermsContainer>
    </>
  )
}
