import React, { FC } from 'react'

import { Box, Link } from '@common/react-lib-base'

export const TermsAndConditionsLabel: FC = () => {
  return (
    <Box>
      {'I have read and agree to the '}
      <Link href={'https://www.caribou.com/terms-of-use'} target={'_blank'}>
        {'Caribou Terms & Conditions'}
      </Link>
      {' and '}
      <Link href="https://www.caribou.com/creditreportauthorization">
        Credit Report Authorization
      </Link>
    </Box>
  )
}
