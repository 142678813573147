import qs from 'query-string'

import { SubmittedVehicle, VehicleInfo, VehicleLookup } from '@common/types'
import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
} from '@src/utils/storage'

import {
  consumerXpAggregatorUrl,
  refiApiUrl,
  vehiclesServiceUrl,
} from '../config'
import { pickNonNil } from '../utils'

export const submitVehicle = async (
  submittedVehicle: SubmittedVehicle,
): Promise<{ resumeUrl: string }> => {
  const body = {
    ...pickNonNil(submittedVehicle),
    requestId: getRequestId(),
  }

  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/submit-vehicle${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(body),
    },
  )

  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export const getVehicle = async (): Promise<VehicleInfo> => {
  const authToken = getAuthToken()
  const requestId = getRequestId()
  if (authToken === null || requestId === null) {
    throw new Error('AuthToken or RequestId not available for request')
  }
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/get-vehicle${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify({ requestId }),
    },
  )

  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export const updateVehicle = async (
  submittedVehicle: SubmittedVehicle,
): Promise<VehicleInfo> => {
  const body = {
    ...pickNonNil(submittedVehicle),
    requestId: getRequestId(),
  }

  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/update-vehicle${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(body),
    },
  )

  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export const updateVehicleLookup = async (
  submittedVehicle: SubmittedVehicle,
): Promise<VehicleInfo> => {
  const body = {
    ...pickNonNil(submittedVehicle),
    requestId: getRequestId(),
  }

  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/update-vehicle-lookup${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(body),
    },
  )

  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export type GetAvailableYearsResponse = ReadonlyArray<string>

export const getAvailableYears =
  async (): Promise<GetAvailableYearsResponse> => {
    const response = await fetch(`${vehiclesServiceUrl}/v1/years`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    })
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data?.message)
    }
    return data
  }

export type GetOption = {
  displayName: string
  value: string
}
export type GetOptionsResponse = ReadonlyArray<GetOption>
export const getMakesByYear = async (
  year: string,
): Promise<GetOptionsResponse> => {
  const response = await fetch(`${vehiclesServiceUrl}/v1/makes?year=${year}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
  })
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data.map((make: string) => ({
    displayName: make,
    value: make,
  }))
}
export type GetModelByMakeAndYearParams = { make: string; year: string }

export const getModels = async ({
  make,
  year,
}: GetModelByMakeAndYearParams): Promise<GetOptionsResponse> => {
  const response = await fetch(
    `${vehiclesServiceUrl}/v1/models?year=${year}&make=${make}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data.map((model: string) => ({
    displayName: model,
    value: model,
  }))
}

export type GetTrimParams = { year: string; make: string; model: string }

export const getTrims = async ({
  year,
  make,
  model,
}: GetTrimParams): Promise<ReadonlyArray<GetOption & { id: string }>> => {
  const response = await fetch(
    `${vehiclesServiceUrl}/v1/trims?year=${year}&make=${make}&model=${model}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data.map(
    ({ trim, partnerUid }: { trim: string; partnerUid: string }) => ({
      displayName: trim,
      value: trim,
      id: partnerUid,
    }),
  )
}

export type TVehicleOptions = {
  desc: string
  code: string
  included: boolean
  retailValue: number
  tradeInValue: number
  inclusive: ReadonlyArray<string> | null
  exclusive: ReadonlyArray<string> | null
}

export const getVehicleOptions = async (): Promise<
  ReadonlyArray<TVehicleOptions>
> => {
  const requestId = getRequestId()
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${requestId}/vehicle-options${getLeadSourceQueryParams()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  if (!response.ok) {
    throw new Error('Problem getting vehicle options')
  }
  return response.json()
}

interface ISubmitVehicleOptions {
  (payload: { options: ReadonlyArray<string> }): Promise<{ resumeUrl: string }>
}

export const submitVehicleOptions: ISubmitVehicleOptions = async ({
  options,
}) => {
  const requestId = getRequestId()
  const codesWithInclude = options.map((code) => ({ code, included: 'true' }))
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${requestId}/vehicle-options${getLeadSourceQueryParams()}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify(codesWithInclude),
    },
  )
  if (!response.ok) {
    throw new Error('Problem submitting vehicle options')
  }
  return response.json()
}

export const lookupVehicle: VehicleLookup = async (args) => {
  const requestId = getRequestId()
  const queryString = qs.stringify(
    'vin' in args
      ? {
          vin: args.vin,
        }
      : {
          lp: args.licensePlate,
          state: args.state,
        },
  )

  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/${requestId}/vehicle-lookup?${queryString}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  if (!response.ok) {
    throw new Error('Problem looking up vehicle')
  }
  return response.json()
}

export const getVehicleInfo = async (
  vehicleUuid: string,
): Promise<VehicleInfo> => {
  const response = await fetch(
    `${refiApiUrl}/services/v1/vehicle/${vehicleUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}
