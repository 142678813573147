/// <reference types="@types/segment-analytics" />

// Note: if needed we can add Segment node to make this universal

type SegmentAnalyticsJS = SegmentAnalytics.AnalyticsJS

type AnalyticsJS = Readonly<{
  page: SegmentAnalyticsJS['page']
  track: SegmentAnalyticsJS['track']
  identify: SegmentAnalyticsJS['identify']
}>

const analyticsMissing = (method: string) => (): void =>
  console.warn(`Failed to call ${method}, analytics not on page`)

const WarningAnalytics: AnalyticsJS = {
  page: analyticsMissing('page'),
  track: analyticsMissing('track'),
  identify: analyticsMissing('identify'),
}

/**
 * Accesses the global window to retrieve the global segment client.
 *
 * WARNING: Only use this function in a context where it is safe to access
 * the global window. For example in a useEffect hook.
 *
 * If segment isn't initialized or is globally blocked it
 * will return a {@see WarningAnalytics} instance.
 */
export const getSegment = (): AnalyticsJS =>
  window.analytics ?? WarningAnalytics
